import { useContext, useEffect, useState } from "react";
import { useLocation } from "wouter";
import { AuthContext } from "../utils/useAuth";
import { handleCreateRoom } from "~/api/roomApi";
import { keyframes, styled } from "~/ui/style/stitches.config";
import { useSnapshot } from "valtio";
import { localState } from "~/state/state";
import { BigButton, BookingBigButton } from "~/ui/components/BigButton";
import { motion } from "framer-motion";
import { FavesBar } from "~/ui/menus/FavesBar";
import { HelpButton } from "~/ui/components/HelpButton";
import { ProDirectory } from "./marketplace/ProDirectory";
import {
  DeckAnimation,
  DeckMarketplace,
  MobileOnlyBr,
} from "./marketplace/DeckMarketplace";
import { UserProfile } from "~/api/userApi";
import { Button } from "~/ui/components/Button";
import { HomeNavBar } from "~/ui/home/HomeNavBar";
import { ProChecklistSection } from "~/ui/home/ProChecklist";
import { ExpandingSection } from "~/ui/home/ExpandingSection";
import { ProfessionalDashboard } from "./marketplace/Availability";
import { Upcoming } from "./marketplace/Upcoming";
import { Marquee } from "~/ui/components/Marquee";
import allProPicsJson from "../preloading/allProPics.json";
import { ProPayoutsOverlay } from "~/ui/home/ProPayoutsOverlay";
import { Message } from "~/ui/components/Message";
import { CartOverlay } from "./marketplace/DeckOverlay";
import { imageUrl } from "~/utils/imageurl";
import { allDecks } from "~/ui/menus/DeckMenu";
import { Deck } from "~/types/room/types";
import {
  setVideoModalVisible,
  VideoModal,
} from "./marketplace/booking/BookingModule";
import { trackEvent } from "~/api/analyticsApi";
import { MoonlightHomepage } from "./MoonlightHompeage";

export enum MOONLIGHT_PAGE {
  HOME,
  DECKS,
  BOOK,
  AVAILABILITY,
  UPCOMING,
  GCAL_TEST,
  CREATE_ROOM,
}

const Home = ({
  user,
  page,
  deckPageId,
}: {
  user: UserProfile | null;
  page: MOONLIGHT_PAGE;
  deckPageId?: string;
}) => {
  const { isConnecting, isOnMobile } = useSnapshot(localState);
  const [isTransitioningToRoom, setIsTransitioningToRoom] = useState(false);
  const [isFavesBarOpen, setIsFavesBarOpen] = useState(false);
  const [activePage, setActivePage] = useState<MOONLIGHT_PAGE>(
    MOONLIGHT_PAGE.HOME
  );
  const [isPayoutsOverlayOpen, setIsPayoutsOverlayOpen] = useState(false);
  const [shouldLongFadeIn, setShouldLongFadeIn] = useState(true);
  const [location, setLocation] = useLocation();
  const auth = useContext(AuthContext);
  const [twoRandomDecks, setTwoRandomDecks] = useState<Deck[]>([]);
  const [fourRandomProPics, setFourRandomProPics] = useState<string[]>([
    "",
    "",
    "",
    "",
  ]);
  const [shouldHoverDeck1, setShouldHoverDeck1] = useState(false);
  const [shouldHoverDeck2, setShouldHoverDeck2] = useState(false);

  useEffect(() => {
    trackEvent("/home/visited_page");
    // get four random pro pics
    const allProPics = allProPicsJson as string[];
    const shuffledProPics = allProPics.sort(() => 0.5 - Math.random());
    const selectedProPics = shuffledProPics.slice(0, 4);
    setFourRandomProPics(selectedProPics);

    // get two random decks
    const shuffledDecks = allDecks.sort(() => 0.5 - Math.random());
    const selectedDecks = shuffledDecks.slice(0, 2);
    setTwoRandomDecks(selectedDecks);
  }, []);

  useEffect(() => {
    if (isTransitioningToRoom && !isConnecting) {
      window.setTimeout(() => {
        handleCreateRoom();
      }, 500);
    }
  }, [isTransitioningToRoom]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.page === "pros") {
      setLocation("/book");
    } else if (params.page === "decks") {
      if (deckPageId) setLocation("/decks/" + deckPageId);
      else setLocation("/decks");
    } else if (params.page === "availability") {
      setLocation("/availability");
    } else if (params.page === "sessions") {
      setLocation("/sessions");
    }
  }, [window.location.search]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.getElementById("main-container")!.scrollTop = 0;

    setActivePage(page);

    if (page === MOONLIGHT_PAGE.CREATE_ROOM) {
      const urlParams = new URLSearchParams(window.location.search);
      const spreadId = urlParams.get("spread");
      console.log(spreadId);
      if (auth.user) {
        document.getElementById("create-room-button")?.click();
      } else {
        const spreadParam = spreadId ? `?spread=${spreadId}` : "";
        window.location = ("/signup?dest=/create-room" +
          spreadParam) as unknown as Location;
      }
    }
  }, [page]);

  return (
    <div
      id="main-container"
      style={{
        position: "relative",
        width: "calc(100vw)",
        height: "calc(100vh)",
        overflow: "auto",
      }}
      onScroll={(e) => {
        if (e.currentTarget.scrollTop > 100) {
          setShouldHoverDeck1(true);
        }
      }}
    >
      <VideoModal
        isHomepageModal={true}
        videoUrl="/images/pro-videos/moonlight_compilation.mp4"
        isBookable={false}
        secondButtonCopy={"Browse all pros"}
        titleCopy="Meet the pros"
        subtitleCopy="Find clarity with a professional guide or explore our tarot sandbox."
        secondCallback={() => {
          trackEvent("/home/popup/browse_all_pros");
          setLocation("/book");
        }}
      />
      <Message />
      <CartOverlay />

      <ProPayoutsOverlay
        isOpen={isPayoutsOverlayOpen}
        setIsOpen={setIsPayoutsOverlayOpen}
      />
      {activePage === MOONLIGHT_PAGE.BOOK && <ProDirectory />}
      {activePage === MOONLIGHT_PAGE.DECKS && (
        <DeckMarketplace deckPageId={deckPageId} />
      )}
      {activePage === MOONLIGHT_PAGE.AVAILABILITY && (
        <ProfessionalDashboard activePage={activePage} />
      )}
      {activePage === MOONLIGHT_PAGE.UPCOMING && <Upcoming />}

      {activePage === MOONLIGHT_PAGE.HOME && (
        <StyledDashboard style={{ backgroundColor: "transparent" }}>
          <MoonlightHomepage />
        </StyledDashboard>

        //   <StyledDashboard
        //     onClick={(e) => {
        //       setIsFavesBarOpen(false);
        //     }}
        //     dontFade={!shouldLongFadeIn}
        //     style={{
        //       display: "flex",
        //     }}
        //     onMouseMove={(e) => {
        //       // set x coordinate to offset from div
        //       //@ts-ignore
        //       document.getElementById("cursor-dot")!.style.left =
        //         e.clientX + "px";
        //       document.getElementById("cursor-dot")!.style.top = e.clientY + "px";
        //     }}
        //   >
        //     <div
        //       id="title-container"
        //       style={{
        //         display: "flex",
        //         // overflow: "hidden",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         marginTop: "40px",
        //       }}
        //     >
        //       <ProChecklistSection
        //         setIsPayoutsOverlayOpen={setIsPayoutsOverlayOpen}
        //         activePage={activePage}
        //       />
        //       <div className="homepage-section" style={{ position: "relative" }}>
        //         <div
        //           style={{
        //             width: "100%",
        //             height: "100%",
        //             opacity: 1,
        //             clipPath: "inset(0 0 0 0)",
        //             display: "inherit",
        //             justifyContent: "inherit",
        //             alignItems: "inherit",
        //             flexDirection: "inherit",
        //           }}
        //         >
        //           <motion.div
        //             key="title"
        //             className="title"
        //             style={{ marginBottom: "12px", zIndex: 1 }}
        //           >
        //             Hello!
        //           </motion.div>
        //           <motion.div
        //             key={"subtitle"}
        //             initial={{ opacity: 0 }}
        //             animate={{ opacity: 1 }}
        //             transition={{ duration: 0.5, delay: 1 }}
        //             className="subtitle"
        //             style={{ marginTop: "0px", zIndex: 1 }}
        //           >
        //             Start a tarot room.{" "}
        //           </motion.div>

        //           <div
        //             style={{
        //               width: "100%",
        //               height: "100%",
        //               opacity: 1,
        //               marginTop: "-10px",
        //               paddingTop: "10px",
        //               clipPath: "inset(0 0 0 0)",
        //               display: "inherit",
        //               justifyContent: "inherit",
        //               alignItems: "inherit",
        //               flexDirection: "inherit",
        //             }}
        //           >
        //             <BigButton
        //               cursorClasses={`transitioning-${isTransitioningToRoom}`}
        //               onClick={() => {
        //                 trackEvent("/home/start_room");
        //                 setIsTransitioningToRoom(true);
        //               }}
        //               enabled={!isFavesBarOpen}
        //               text={"CREATE A RITUAL"}
        //             />

        //             <motion.div
        //               initial={{ opacity: 0 }}
        //               animate={{ opacity: 1 }}
        //               transition={{ duration: 0.5, delay: 1.5 }}
        //               key="taglines"
        //               className="taglines"
        //             >
        //               <div className="tagline">
        //                 <img src="/images/video.svg" />
        //                 <div className="text">video & audio included</div>
        //               </div>
        //             </motion.div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //     <div className="homepage-section" style={{ position: "relative" }}>
        //       <div
        //         style={{
        //           width: "100%",
        //           height: "100%",
        //           opacity: 1,
        //           display: "inherit",
        //           justifyContent: "inherit",
        //           alignItems: "inherit",
        //           flexDirection: "inherit",
        //         }}
        //       >
        //         <motion.div
        //           key="title"
        //           className="title"
        //           style={{ marginBottom: "12px", zIndex: 1, lineHeight: 1.3 }}
        //         >
        //           Book a tarot session
        //         </motion.div>
        //         <motion.div
        //           key={"subtitle"}
        //           initial={{ opacity: 0 }}
        //           animate={{ opacity: 1 }}
        //           transition={{ duration: 0.5, delay: 1 }}
        //           className="subtitle"
        //           style={{ marginTop: "2px", zIndex: 1, marginBottom: "12px" }}
        //         >
        //           Readings & lessons open to all.
        //         </motion.div>

        //         <div className="video-container" style={{ position: "relative" }}>
        //           <video
        //             autoPlay
        //             loop
        //             muted
        //             playsInline
        //             className="video"
        //             style={{
        //               width: "300px",
        //               borderRadius: "15px",
        //               border: "1px solid black",
        //               marginBottom: "20px",
        //               marginTop: "10px",
        //               cursor: "pointer",
        //             }}
        //             onClick={() => {
        //               setVideoModalVisible(true);
        //             }}
        //           >
        //             <source
        //               src={"/images/moonlight-landing.mp4"}
        //               type="video/mp4"
        //             />
        //           </video>
        //           <Button
        //             className="start-button"
        //             style={{
        //               position: "absolute",
        //               top: 0,
        //               left: 0,
        //               right: 0,
        //               bottom: 0,
        //               margin: "auto",
        //               height: "44px",
        //               width: "130px",
        //               padding: 0,
        //               fontSize: "12px",
        //               borderRadius: "13px",
        //             }}
        //             onClick={() => {
        //               trackEvent("/home/open_booking_popup");
        //               setVideoModalVisible(true);
        //             }}
        //           >
        //             Meet our readers
        //           </Button>
        //         </div>
        //       </div>
        //     </div>
        //     <div className="homepage-section" style={{ position: "relative" }}>
        //       <div
        //         style={{
        //           width: "100%",
        //           height: "100%",
        //           opacity: 1,
        //           clipPath: "inset(0 0 0 0)",
        //           display: "inherit",
        //           justifyContent: "inherit",
        //           alignItems: "inherit",
        //           flexDirection: "inherit",
        //         }}
        //       >
        //         <motion.div
        //           key={"subtitle"}
        //           initial={{ opacity: 0 }}
        //           animate={{ opacity: 1 }}
        //           transition={{ duration: 0.5, delay: 1 }}
        //           className="subtitle"
        //         >
        //           Access digital decks with guidebooks.
        //         </motion.div>
        //         {twoRandomDecks && twoRandomDecks.length > 1 && (
        //           <div
        //             className="deck-animations"
        //             style={{
        //               display: "flex",
        //               justifyContent: "space-between",
        //               alignItems: "center",
        //               marginTop: "70px",
        //               width: "60%",
        //             }}
        //           >
        //             <div
        //               className="deck-animation-container"
        //               style={{
        //                 transform: isOnMobile ? "scale(0.9)" : "scale(0.7)",
        //                 marginLeft: isOnMobile ? "60px" : "",
        //                 zIndex: 2,
        //               }}
        //               onPointerEnter={(e) => {
        //                 if (!isOnMobile) {
        //                   setShouldHoverDeck1(true);
        //                   setShouldHoverDeck2(false);
        //                 }
        //               }}
        //               onPointerLeave={(e) => {
        //                 if (!isOnMobile) {
        //                   setShouldHoverDeck1(false);
        //                 }
        //               }}
        //               onClick={() => {
        //                 if (!isOnMobile) {
        //                   setLocation("/decks");
        //                 } else {
        //                   if (shouldHoverDeck1) {
        //                     setShouldHoverDeck1(false);
        //                   } else {
        //                     setShouldHoverDeck1(true);
        //                   }
        //                 }
        //               }}
        //             >
        //               <DeckAnimation
        //                 deck={twoRandomDecks[0]}
        //                 isHovered={shouldHoverDeck1}
        //               />
        //             </div>
        //             {!isOnMobile && (
        //               <div
        //                 className="deck-animation-container"
        //                 style={{ transform: "scale(0.7)" }}
        //                 onPointerEnter={(e) => {
        //                   setShouldHoverDeck2(true);
        //                   setShouldHoverDeck1(false);
        //                 }}
        //                 onPointerLeave={(e) => {
        //                   setShouldHoverDeck2(false);
        //                 }}
        //                 onClick={() => {
        //                   if (!isOnMobile) {
        //                     setLocation("/decks");
        //                   }
        //                 }}
        //               >
        //                 <DeckAnimation
        //                   deck={twoRandomDecks[1]}
        //                   isHovered={shouldHoverDeck2}
        //                 />
        //               </div>
        //             )}
        //           </div>
        //         )}
        //         <Button
        //           onClick={() => {
        //             trackEvent("/home/shop_decks");
        //             setLocation("/decks");
        //           }}
        //           className="shop-decks-button"
        //           style={{
        //             fontSize: "12px",
        //             padding: "12px 0px",
        //             width: "130px",
        //             borderRadius: "30px",
        //           }}
        //           darkHover
        //         >
        //           Shop decks
        //         </Button>
        //       </div>
        //     </div>

        //     <div
        //       className="homepage-section"
        //       style={{ marginBottom: "150px", zIndex: 1 }}
        //     >
        //       <motion.div
        //         key={"subtitle"}
        //         initial={{ opacity: 0 }}
        //         animate={{ opacity: 1 }}
        //         transition={{ duration: 0.5, delay: 1 }}
        //         className="subtitle"
        //       >
        //         {user?.isProfessional
        //           ? "Explore our professional community."
        //           : "Explore our professional community."}
        //       </motion.div>
        //       <div className="tarot-pics">
        //         <img
        //           src={imageUrl(`/rotating-pros${fourRandomProPics[0]}`, 140)}
        //           onClick={() => {
        //             window.open("/book", "_blank");
        //           }}
        //         />
        //         <img
        //           src={imageUrl(`/rotating-pros${fourRandomProPics[1]}`, 140)}
        //           onClick={() => {
        //             window.open("/book", "_blank");
        //           }}
        //         />
        //         <img
        //           src={imageUrl(`/rotating-pros${fourRandomProPics[2]}`, 140)}
        //           onClick={() => {
        //             window.open("/book", "_blank");
        //           }}
        //         />
        //         <img
        //           src={imageUrl(`/rotating-pros${fourRandomProPics[3]}`, 140)}
        //           onClick={() => {
        //             window.open("/book", "_blank");
        //           }}
        //         />
        //       </div>
        //       <Button
        //         onClick={() => {
        //           trackEvent("/home/see_all_readers");
        //           setLocation("/book");
        //         }}
        //         style={{
        //           fontSize: "12px",
        //           padding: "12px 0px",
        //           width: "130px",
        //           borderRadius: "30px",
        //           marginTop: "30px",
        //         }}
        //         darkHover
        //       >
        //         {user?.isProfessional ? "See profiles" : "See all readers"}
        //       </Button>

        //       <ExpandingSection
        //         title={
        //           user?.isProfessional
        //             ? "About our tarot community"
        //             : "Learn more about tarot"
        //         }
        //         text={
        //           user?.isProfessional
        //             ? "<span class='a'>W</span>elcome! We’re thrilled you’re here. Together we embrace a spectrum of styles and approaches to creating rituals, with shared philosophies to:<br/><br/>1. Empower people through the art of tarot<br/>2. Facilitate sessions with compassion and integrity<br/>3. Elevate tarot as a respected professional field<br/><br/>By gathering as professionals, we help tarot blossom. We're excited to share its magic and benefits with more people.<br/><br/>We'd love to hear your thoughts! Moonlight is crafted in conversation with the community."
        //             : "<span class='a'>T</span>arot is a playful and transformative tool for exploring possibilities, filled with beautiful visual metaphors. It's fun, illuminating, and empowering. If you're entering a new chapter, craving a fresh perspective, or seeking to deepen your curiosity, our community of professional readers & teachers is here for you."
        //         }
        //       />
        //     </div>

        //     {!isTransitioningToRoom && (
        //       <Marquee
        //         page={"home"}
        //         onClick={() => {
        //           trackEvent("/home/pressed_bottom_bar");
        //         }}
        //         text={
        //           "<a class='marquee-text' href='https://www.instagram.com/entermoonlight/' target='_blank'>welcome to moonlight ・ follow us @entermoonlight</a>"
        //         }
        //       />
        //     )}
        //     {auth.user && (
        //       <FavesBar
        //         isOpen={isFavesBarOpen}
        //         setIsOpen={setIsFavesBarOpen}
        //       ></FavesBar>
        //     )}
        //   </StyledDashboard>
      )}
      <StyledHelpButton>
        <HelpButton />
      </StyledHelpButton>
      <HomeNavBar
        activePage={activePage}
        setActivePage={setActivePage}
        setShouldLongFadeIn={setShouldLongFadeIn}
        setIsPayoutsOverlayOpen={setIsPayoutsOverlayOpen}
      />
    </div>
  );
};

export default Home;

const fadeinNoDelay = keyframes({
  "0%": {
    opacity: 0,
    backgroundColor: "$wash",
  },

  "100%": {
    opacity: 1,
    backgroundColor: "$wash",
  },
});

const fadein = keyframes({
  "0%": {
    opacity: 0,
    backgroundColor: "$wash",
  },
  "25%": {
    opacity: 0,
    backgroundColor: "$wash",
  },
  "100%": {
    opacity: 1,
    backgroundColor: "$wash",
  },
});

export const StyledHelpButton = styled(motion.div, {
  "@mobile": {
    "& button": {
      transform: "translateY(-32px)",
    },
  },
});

const StyledDashboard = styled("div", {
  marginLeft: "calc($navWidth - 20px)",
  width: "calc(100% - $navWidth)",
  "@mobile": {
    marginLeft: "0px",
    width: "100%",
    "& #title-container": {
      width: "100%",
    },
    marginBottom: "70px",
    overflow: "hidden",
  },

  "& .homepage-section": {
    marginTop: "20px",
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    width: "500px",
    border: "0.5px solid rgba(0, 0, 0, 0.4)",
    borderRadius: "31px",
    boxShadow: "0px 3px 0px 0px rgba(0, 0, 0, 1)",
    padding: "50px 0px",
    alignItems: "center",
    "@mobile": {
      width: "calc(100% - 32px)",
    },
    "&.big-button-container": {
      flexDirection: "row",
      justifyContent: "space-around",
    },

    "& button": {
      "@mobile": {
        fontSize: "13px !important",
        padding: "18px 0px",
        width: "140px !important",
        height: "44px !important",
        "&.shop-decks-button": {
          marginTop: "8px",
        },
        "&.start-button": {
          width: "150px !important",
          fontSize: "14px !important",
        },
      },
    },
  },
  "& .big-button-section": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "-100px",
  },

  "& .deck-animation-container": {
    "@mobile": {
      marignTop: "-50px",
      marginBottom: "30px",
    },
  },

  "& .tarot-pics": {
    marginTop: "30px",
    "& img": {
      width: "70px",
      height: "144px",
      objectFit: "cover",
      // center cover position of images
      borderRadius: "35px",
      border: "1px solid black",
      marginLeft: "2px",
      marginRight: "2px",
      cursor: "pointer",
    },
  },

  "& .help-button": {
    "@mobile": {
      "& button": {
        transform: "translateY(-56px)",
      },
      // transform: "translateY(-1px)",
    },
  },

  position: "relative",

  // height: "100vh",
  display: "flex",
  alignItems: "center",
  // justifyContent: "center",
  flexDirection: "column",
  backgroundColor: "$wash",
  "& .buttons": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "24px",
  },
  "& .button-container": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& .subtext": {
      fontSize: "11px",

      marginTop: "14px",
    },
  },

  "& .title, & .subtitle": {
    zIndex: "100",
    pointerEvents: "none",
  },

  "& .title": {
    fontFamily: "GorgeousBaby",
    fontSize: "40px",
    // marginTop: "-60px",
  },

  "& .subtitle": {
    fontFamily: "KeplerLightCondensed",
    fontSize: "24px",
    marginTop: "10px",
    "@mobile": {
      marginTop: "8px",
      fontSize: "26px",
    },
  },
  "& .taglines": {
    display: "flex",
    zIndex: "10",

    "& .tagline": {
      marginLeft: "12px",
      marginRight: "12px",
      marginTop: "-12px",
      display: "flex",
      fontSize: "12px",
      "@mobile": {
        fontSize: "13px",
      },
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        marginRight: "4px",
      },
    },
    "@mobile": {
      flexDirection: "column !important",
      // marginTop: "-60px",

      "& .tagline": {
        // marginTop: "8px",
      },
    },
  },

  variants: {
    dontFade: {
      true: {
        animation: `${fadeinNoDelay} 0.4s`,
      },
      false: {
        animation: `${fadein} 3s`,
      },
    },
  },
});
