import { styled } from "@stitches/react";
import { set } from "date-fns";
import { is } from "date-fns/locale";
import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { Button } from "~/ui/components/Button";
import { keyframes } from "~/ui/style/stitches.config";

export const ParticleBlob: React.FC = () => {
  const mountRef = useRef<HTMLDivElement | null>(null);
  let mouse = new THREE.Vector2();
  let prevMouse = new THREE.Vector2(); // Previous mouse position
  const blobRadius = 2; // Blob size
  const movementThreshold = 0.001; // Minimum mouse movement to trigger interaction
  let time = 0; // Time variable for fluid motion

  useEffect(() => {
    const mount = mountRef.current!;

    // Create scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.z = 5;

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true }); // Transparent background
    renderer.setSize(window.innerWidth, window.innerHeight);
    mount.appendChild(renderer.domElement);

    // Particle setup
    const particleCount = 5000;
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const originalPositions = new Float32Array(particleCount * 3); // Store original positions

    for (let i = 0; i < particleCount; i++) {
      const radius = Math.random() * blobRadius;
      const angle = Math.random() * Math.PI * 2;

      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);
      const z = (Math.random() - 0.5) * 0.1; // Keep it flatter for smoother blending

      positions[i * 3] = x;
      positions[i * 3 + 1] = y;
      positions[i * 3 + 2] = z;

      originalPositions[i * 3] = x;
      originalPositions[i * 3 + 1] = y;
      originalPositions[i * 3 + 2] = z;
    }

    geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));

    // Material with soft blending and smooth color transition
    const material = new THREE.ShaderMaterial({
      uniforms: {
        uCenterColor: { value: new THREE.Color(0xd28fff) }, // Purple center
        uEdgeColor: { value: new THREE.Color(0xffddc1) }, // Peachy edge
        uTime: { value: 0.0 }, // Time for motion
      },
      vertexShader: `
        uniform float uTime;
        varying vec3 vPosition;
        void main() {
          vPosition = position;
          
          // Apply a subtle fluid motion over time
          vec3 fluidMovement = vec3(
            sin(position.x * 2.0 + uTime) * 0.01,
            cos(position.y * 2.0 + uTime) * 0.01,
            sin(position.z * 2.0 + uTime) * 0.01
          );
          
          vec3 newPosition = position + fluidMovement;  // Fluid-like motion
          gl_PointSize = 2.0;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 uCenterColor;
        uniform vec3 uEdgeColor;
        varying vec3 vPosition;

        void main() {
          // Smooth gradient between center and edge colors
          float dist = length(vPosition);
          vec3 color = mix(uCenterColor, uEdgeColor, dist / 1.2);
          gl_FragColor = vec4(color, 0.5);  // Soft transparency

          // Make particles round
          if (length(gl_PointCoord - vec2(0.5)) > 0.4) discard;
        }
      `,
      transparent: true,
      depthTest: false,
      blending: THREE.NormalBlending, // Preserve color accuracy
    });

    const particles = new THREE.Points(geometry, material);
    scene.add(particles);

    // Fix mouse movement issue
    const handleMouseMove = (event: MouseEvent) => {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    };

    // Attach the event listener for mouse move
    window.addEventListener("mousemove", handleMouseMove);

    // Animation loop with fluid motion
    const animate = () => {
      requestAnimationFrame(animate);
      time += 0.01;
      material.uniforms.uTime.value = time; // Update time uniform

      const positionsArray = geometry.attributes.position.array as Float32Array;

      for (let i = 0; i < particleCount; i++) {
        const dx = mouse.x * 2 - positionsArray[i * 3];
        const dy = mouse.y * 2 - positionsArray[i * 3 + 1];
        const distance = Math.sqrt(dx * dx + dy * dy);

        // Move particles based on proximity to mouse
        if (distance < 0.5) {
          positionsArray[i * 3] += (positionsArray[i * 3] - mouse.x) * 0.05;
          positionsArray[i * 3 + 1] +=
            (positionsArray[i * 3 + 1] - mouse.y) * 0.05;
        } else {
          // Return particles to original position smoothly
          positionsArray[i * 3] +=
            (originalPositions[i * 3] - positionsArray[i * 3]) * 0.02;
          positionsArray[i * 3 + 1] +=
            (originalPositions[i * 3 + 1] - positionsArray[i * 3 + 1]) * 0.02;
        }
      }

      geometry.attributes.position.needsUpdate = true; // Update positions
      renderer.render(scene, camera); // Render scene
    };

    animate();

    // Cleanup
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      mount.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} />;
};

const vertexShader = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = vec4(position, 1.0);
  }
`;

const fragmentShader = `
  uniform vec2 u_resolution;
  uniform vec2 u_mouse;
  uniform float u_time;
  
  varying vec2 vUv;
  
  // Simplex 2D noise
  vec3 permute(vec3 x) { return mod(((x*34.0)+1.0)*x, 289.0); }
  float snoise(vec2 v){
    const vec4 C = vec4(0.211324865405187, 0.366025403784439,
             -0.577350269189626, 0.024390243902439);
    vec2 i  = floor(v + dot(v, C.yy) );
    vec2 x0 = v -   i + dot(i, C.xx);
    vec2 i1;
    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
    vec4 x12 = x0.xyxy + C.xxzz;
    x12.xy -= i1;
    i = mod(i, 289.0);
    vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
    + i.x + vec3(0.0, i1.x, 1.0 ));
    vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy),
      dot(x12.zw,x12.zw)), 0.0);
    m = m*m ;
    m = m*m ;
    vec3 x = 2.0 * fract(p * C.www) - 1.0;
    vec3 h = abs(x) - 0.5;
    vec3 ox = floor(x + 0.5);
    vec3 a0 = x - ox;
    m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
    vec3 g;
    g.x  = a0.x  * x0.x  + h.x  * x0.y;
    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
    return 130.0 * dot(m, g);
  }
  
  void main() {
    vec2 st = (gl_FragCoord.xy - u_resolution * 0.5) / 500.0 + 0.5;
    vec2 center = vec2(0.5, 0.5);
    
    // Enhance shimmering effect
    float shimmer = snoise(st * 2.0 + u_time * 0.3) * 0.04;
    
    // Enhance mouse influence
    vec2 mousePos = (u_mouse - u_resolution * 0.5) / 500.0 + 0.5;
    vec2 mouseVector = st - mousePos;
    float mouseDist = length(mouseVector);
    float mouseInfluence = exp(-mouseDist * 2.0) * 0.2;
    
    // Combine shimmering and mouse influence
    vec2 distortedSt = st + shimmer * vec2(cos(u_time), sin(u_time)) + mouseVector * mouseInfluence;
    
    // Calculate distance from center for gradient
    float dist = distance(distortedSt, center);
    
    // Create circular mask with soft edges
    float radius = 0.4;
    float circle = smoothstep(radius, radius - 0.4, dist);
    
    // Create gradient colors
    vec3 purpleColor = vec3(0.9137, 0.7255, 1.0);
    vec3 peachColor = vec3(0.98, 0.91, 0.87);
    vec3 color = mix(purpleColor, peachColor, smoothstep(0.0, radius, dist));
    
    // Apply circular mask
    gl_FragColor = vec4(purpleColor, circle);
  }
`;

const Gradient: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.OrthographicCamera | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const materialRef = useRef<THREE.ShaderMaterial | null>(null);
  const frameIdRef = useRef<number | null>(null);

  let lastMouse = new THREE.Vector2();
  let velocity = new THREE.Vector2();

  useEffect(() => {
    if (!mountRef.current) return;

    const width = 500;
    const height = 500;

    // Scene setup
    sceneRef.current = new THREE.Scene();
    cameraRef.current = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    rendererRef.current = new THREE.WebGLRenderer({ alpha: true });
    rendererRef.current.setSize(width, height);
    mountRef.current.appendChild(rendererRef.current.domElement);

    const geometry = new THREE.PlaneGeometry(2, 2);
    materialRef.current = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        u_time: { value: 0 },
        u_resolution: { value: new THREE.Vector2(width, height) },
        u_mouse: { value: new THREE.Vector2(width / 2, height / 2) },
      },
      transparent: true,
    });

    const mesh = new THREE.Mesh(geometry, materialRef.current);
    sceneRef.current.add(mesh);

    // Animation loop
    const animate = (time: number) => {
      if (
        materialRef.current &&
        rendererRef.current &&
        sceneRef.current &&
        cameraRef.current
      ) {
        materialRef.current.uniforms.u_time.value = time / 1000;
        rendererRef.current.render(sceneRef.current, cameraRef.current);
      }
      frameIdRef.current = requestAnimationFrame(animate);
    };

    frameIdRef.current = requestAnimationFrame(animate);

    // Event listeners
    const handleMouseMove = (event: MouseEvent) => {
      if (materialRef.current && mountRef.current) {
        const rect = mountRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const mousePos = new THREE.Vector2(x, height - y);

        // Calculate velocity (difference between frames)
        velocity.subVectors(mousePos, lastMouse);

        // Update the uniforms
        materialRef.current.uniforms.u_mouse.value.set(x, height - y);
        materialRef.current.uniforms.u_velocity.value.set(
          velocity.x,
          velocity.y
        );

        lastMouse.copy(mousePos); // Update the last mouse position
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup
    return () => {
      if (frameIdRef.current) cancelAnimationFrame(frameIdRef.current);
      window.removeEventListener("mousemove", handleMouseMove);
      if (mountRef.current && rendererRef.current) {
        mountRef.current.removeChild(rendererRef.current.domElement);
      }
    };
  }, []);

  return (
    <div
      ref={mountRef}
      style={{
        width: "500px",
        height: "500px",
        position: "absolute",
        left: "50%",
        top: "-120px",
        transform: "translate(-50%, 0%) scale(1.0)",
        zIndex: -1,
      }}
    />
  );
};

const themes = {
  "work stuff": [
    "Relationship question 1?",
    "Relationship question 2? This is a longer one. Really wondering here",
    "Relationship question 3?",
  ],
  "the heart": [
    "Work question 1? This is a longer one. Really wondering here",
    "Work question 2?",
    "Work question 3?",
  ],
  "inner magic": [
    "Crossroads question 1?",
    "Crossroads question 2?",
    "Crossroads question 3? This is a longer one. Really wondering here",
  ],
  "vibe check": [
    "Plot twist question 1? This is a longer one. Really wondering here",
    "Plot twist question 2?",
    "Plot twist question 3?",
  ],
  "next chapter": [
    "Purpose question 1?",
    "Purpose question 2? This is a longer one. Really wondering here",
    "Purpose question 3?",
  ],
};

enum HOMEPAGE_PHASE {
  THEME_SELECTION,
  QUESTION_SELECTION,
  READING_SELECTION,
}

const Wheel = ({}) => {
  const numWheelPieces = 5;
  const pieColors = ["#E9B9FF", "#F3E4D4", "#F6B687", "#F2C889", "#F8E08A"];
  const radius = 120;
  const centerX = 200;
  const centerY = 200;

  const polarToCartesian = (
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number
  ) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const createPieSlice = (
    startAngle: number,
    endAngle: number,
    color: string
  ) => {
    // Calculate start and end points using the correct angles
    const start = polarToCartesian(centerX, centerY, radius, startAngle);
    const end = polarToCartesian(centerX, centerY, radius, endAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    // Set sweep flag to 0 (for counterclockwise direction) and adjust path
    return `M ${centerX} ${centerY} L ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${end.x} ${end.y} Z`;
  };

  const anglePerPiece = 360 / numWheelPieces;
  const textRadius = 128; // Adjust this to control how far the text is from the center

  const [hoveredIdx, setHoveredIdx] = useState<number | null>(null);

  return (
    <StyledWheel
      className={`wheel-container hovered-${hoveredIdx !== null}`}
      style={{
        width: "400px",
        height: "400px",
        marginTop: "-40px",
        position: "relative",
      }}
    >
      {/* Rotating Arced Text */}
      <div
        className="text-rotate"
        style={{
          position: "absolute",
        }}
      >
        <svg width="400" height="400" viewBox="0 0 400 400">
          <defs>
            <path
              id="circlePath"
              d={`
                M ${centerX - textRadius}, ${centerY}
                a ${textRadius} ${textRadius} 0 1, 1 ${textRadius * 2}, 0
                a ${textRadius} ${textRadius} 0 1, 1 -${textRadius * 2}, 0
              `}
              fill="none"
              stroke="transparent"
            />
          </defs>
          <text fontSize="16px" fontWeight="bold" fill="#000">
            <textPath
              href="#circlePath"
              startOffset="50%"
              textAnchor="middle"
              fontSize="9px"
              letterSpacing={3}
              fontWeight="300"
            >
              PICK A TAROT TOPIC
            </textPath>
          </text>
        </svg>
      </div>

      <svg
        className={`wheel `}
        viewBox="0 0 400 400"
        style={{ zIndex: 99999, position: "absolute" }}
      >
        {Object.keys(themes).map((theme, i) => {
          const startAngle = i * anglePerPiece;
          const endAngle = (i + 1) * anglePerPiece;
          const pathData = createPieSlice(
            startAngle,
            endAngle,
            pieColors[i % pieColors.length]
          );

          const middleAngle = (startAngle + endAngle) / 2; // Middle angle of the slice

          // Calculate the center of the slice
          const textX =
            centerX +
            0.6 * radius * Math.cos((middleAngle - 90) * (Math.PI / 180));
          const textY =
            centerY +
            0.6 * radius * Math.sin((middleAngle - 90) * (Math.PI / 180));

          // Split theme into two lines, assuming it's a two-word string for now
          const [line1, line2] = theme.split(" ");

          return (
            <g
              key={`wheel-${i}`}
              className={`wheel-slice other-piece-hovered-${
                hoveredIdx !== null && hoveredIdx !== i
              }`}
              onMouseEnter={() => {
                setHoveredIdx(i);
              }}
              onMouseLeave={(e) => {
                if (
                  (e.relatedTarget as HTMLElement).classList.contains(
                    "wheel-slice"
                  )
                ) {
                  return;
                } else {
                  setHoveredIdx(null);
                }
              }}
            >
              <path
                d={pathData}
                fill={pieColors[i % pieColors.length]}
                style={{
                  transformOrigin: `${centerX}px ${centerY}px`,
                }}
              />
              <text
                x={textX}
                y={textY}
                textAnchor="middle"
                fill="#000"
                fontSize="12px"
                fontWeight="400"
              >
                <tspan x={textX} dy="-0.5em">
                  {line1}
                </tspan>
                <tspan x={textX} dy="1.2em">
                  {line2}
                </tspan>
              </text>
            </g>
          );
        })}
      </svg>
    </StyledWheel>
  );
};

const rotate = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

const StyledWheel = styled("div", {
  "& path": {
    transition: "all 0.2s",
  },
  transition: "all 0.2s",

  "& .wheel-slice:hover": {
    cursor: "pointer",
    "& path": {
      fill: "black",
    },
    "& text": {
      transition: "all 0.2s",

      fill: "white",
    },
  },

  "&.hovered-true": {
    "& .wheel": {
      transform: "scale(1.16)",
    },
  },
  "& .wheel": {
    transition: "all 0.3s ease-out",
  },
  "& .text-rotate": {
    animation: `${rotate} 30s linear infinite`, // Infinite rotation animation
  },
});

export const MoonlightHomepage = () => {
  const [currPhase, setCurrPhase] = useState<HOMEPAGE_PHASE>(
    HOMEPAGE_PHASE.THEME_SELECTION
  );

  const [isTransitioning, setIsTransitioning] = useState(true);
  const [currTitle, setCurrTitle] = useState("");
  const [currSubtitle, setCurrSubtitle] = useState("");
  const [areQuestionsVisible, setAreQuestionsVisible] = useState(false);

  const [currContent, setCurrContent] = useState<React.ReactNode>(<div></div>);

  const themeSelectionContent = <Wheel />;

  const questionSelectionContent = (
    <div className="question-select">
      <textarea
        onFocus={() => {
          console.log("heere");
          setAreQuestionsVisible(true);
        }}
        placeholder="Type your question"
        style={{ width: "400px", height: "100px" }}
      />
      <div
        className={`question-option-container visible-${areQuestionsVisible}`}
      >
        {themes["next chapter"].map((question) => (
          <div className="question-option" key={question}>
            {question}
          </div>
        ))}
      </div>
      <Button
        style={{
          marginTop: "12px",
          padding: "10px 24px",
          borderRadius: "11px",
        }}
        darkHover
        onClick={() => {
          setCurrPhase(HOMEPAGE_PHASE.READING_SELECTION);
        }}
      >
        Next
      </Button>
    </div>
  );

  useEffect(() => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
    }, 800);

    setTimeout(() => {
      if (currPhase === HOMEPAGE_PHASE.THEME_SELECTION) {
        setCurrTitle("Hi!");
        setCurrSubtitle("What are you seeking clarity on?");
        setCurrContent(themeSelectionContent);
      } else if (currPhase === HOMEPAGE_PHASE.QUESTION_SELECTION) {
        setCurrTitle("Think of a question");
        setCurrSubtitle("No need to get it perfect!");
        setCurrContent(questionSelectionContent);
      } else if (currPhase === HOMEPAGE_PHASE.READING_SELECTION) {
        setCurrTitle("Pick your reading style");
        setCurrSubtitle("");
        setCurrContent(<div></div>);
      }
    }, 600);
  }, [currPhase]);

  return (
    <StyledMoonlightHompeage
      isTransitioning={isTransitioning}
      style={{ position: "relative" }}
    >
      <div className="text-container">
        <div className="title">{currTitle}</div>
        <div className="subtitle">{currSubtitle}</div>
      </div>
      <div className="content"> {currContent}</div>

      <div>
        <Gradient />
      </div>
    </StyledMoonlightHompeage>
  );
};

const StyledMoonlightHompeage = styled("div", {
  marginTop: "220px",
  position: "relative",
  backgroundColor: "transparent",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  "& .text-container": {
    marginTop: "-50px",
    transition: "all 0.6s ease-out",
    textAlign: "center",
  },
  "& .title": {
    marginBottom: "20px",
    fontSize: "55px",
  },
  "& .content": {
    transition: "all 0.6s ease-out",
    opacity: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  "& .crossroads-button-container": {
    display: "flex", // This enables flexbox
    flexWrap: "wrap", // Allows buttons to wrap if the container is too narrow
    justifyContent: "space-around", // Adds space between buttons
    width: "500px", // Set an explicit width for the container
    margin: "0 auto", // Center the container horizontally
    marginTop: "30px",
    "& button": {
      margin: "10px", // Adds margin around the buttons
      padding: "20px 0px", // Space inside the button
      backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent white
      color: "black",
      border: "1px solid rgba(255, 255, 255, 0.5)", // Transparent black
      borderRadius: "18px",
      cursor: "pointer",
      width: "130px", // Ensure buttons don’t get too wide
      flexShrink: 0, // Prevent buttons from shrinking
      transition: "all 0.3s ease-out",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        width: "160px",
      },
    },
  },
  "& .question-select": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "20px",
    "& textarea": {
      backgroundColor: "rgba(255, 255, 255, 0.38)",
      color: "black",
      border: "1px solid rgba(0, 0, 0, 0.3)",
      borderRadius: "18px",
      width: "500px !important",
      height: "48px !important",
      lineHeight: "1.5",
      fontSize: "16px",
      padding: "10px 16px",
      marginBottom: "20px",
      resize: "none",
      overflowX: "auto",
      overflowY: " hidden",
      whiteSpace: "nowrap",
      boxSize: "border-box",
      "&::placeholder": {
        color: "rgba(0,0,0,0.7)",
      },
      "&:focus": {
        outline: "none",
        border: "1px solid black",
      },
    },
    "& .question-option-container": {
      backgroundColor: "white",
      padding: "16px 20px",

      width: "500px",
      marginTop: "0px",
      border: "1px solid rgba(0, 0, 0, 0.3)",
      fontSize: "13px",
      color: "rgba(0, 0, 0, 0.7)",
      borderRadius: "18px",
      "& .question-option": {
        padding: "10px 0",
      },
      "&.visible-true": {
        opacity: 1,
      },
      "&.visible-false": {
        opacity: 1,
      },
      transition: "all 0.6s ease-out",
    },
  },
  variants: {
    isTransitioning: {
      true: {
        "& .content": {
          opacity: 0,
          transition: "all 0.6s ease-out",
        },
        "& .text-container": {
          opacity: 0,
          transition: "all 0.6s ease-out",
        },
      },
      false: {
        "& .content": {
          opacity: 1,
          transition: "all 0.6s ease-in",
        },
        "& .text-container": {
          opacity: 1,
          transition: "all 0.6s ease-in",
        },
      },
    },
  },
});
